.App {
  text-align: center;
  padding-top: 3rem;
}

.balanceScale {
  display: flex;
}

.scaleBlocks {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 25px;
  /*overflow: auto;*/
  border: solid 2px #d0d0d0;
  border-radius: .2em;
  /*width: 200px;*/
  flex: 1;
  flex-wrap: wrap;
}

.blocks {
  list-style: none;
  display: flex;
  padding: 10px;
  /*overflow: auto;*/
  border: solid 2px #d0d0d0;
  border-radius: .2em;
  margin-top: 1em;
  flex-wrap: wrap;
}

.App li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: .2em;
  padding: .5em;
  margin-bottom: 1em;
}

.App p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}